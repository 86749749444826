import React from "react"
import { synkliCapturePage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const SynkliCapture = () => {
  return (
    <Layout>
      <Seo
        title="Synkli Capture: Boost Conversions with Easy Lead Management"
        description={`Boost conversions with Synkli Capture, a tool designed to help accountants gather leads. Easily integrate with WordPress for accurate and complete client data.`}
      />

      <FeatureDetailsPage data={synkliCapturePage} />
      
    </Layout>
  )
}

export default SynkliCapture
